<script lang="ts" setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/store/useAppStore'
import { Notify } from 'quasar'
import BaseProgressBar from '@/components/base/BaseProgressBar.vue'
import BaseFooter from '@/components/base/BaseFooter.vue'
import SurveyQuestion from '@/components/survey/SurveyQuestion.vue'
import { Survey } from '@/models/survey/Survey'
import { Question } from '@/models/survey/Question'
import { DependantQuestion } from '@/models/survey/DependantQuestion'

const router = useRouter()
const userApi = useApi()
const route = useRoute()
const page = ref(0)
const isRecapButtonLoading = ref(false)
const isNextLoading = ref(false)
const isPreviousLoading = ref(false)
const isDisable = ref(false)
const survey = ref<Survey>()
const showGlobalVariables = ref(false)
const iFrameRef = ref()

survey.value = await userApi.getSurvey(route.params.documentId)

if (route.query.page) {
  page.value = +route.query.page
}
const isAllQuestionHidden = computed(() => {
  const result = survey.value?.pages[page.value - 1].questions.find((question) => {
    return !question.isGlobal || !question.isGlobalValue
  })
  return !result
})

function isQuestionVisible(question: Question): boolean {
  let isVisible: boolean | undefined = true
  let isVisibleArray: Array<boolean> = []

  if (question.label === "embedded") {
    return false
  }

  if (question.dependantQuestions.length === 0) {
    return true
  }
  for (let i = 0; i < question.dependantQuestions.length; i++) {
    const dependantQuestion = question.dependantQuestions[i]
    const parentQuestion = findDependantQuestion(dependantQuestion)
    if (parentQuestion === undefined) {
      return true
    }
    if (!isQuestionVisible(parentQuestion)) {
      return false
    }
    let dependantQuestionTest = getDependantValue(dependantQuestion, parentQuestion)
    isVisibleArray.push(dependantQuestionTest)
  }

  isVisible = isVisibleArray.every((iva) => {
    return iva
  })
  return isVisible
}

function findDependantQuestion(dependantQuestion: DependantQuestion): Question | undefined {
  const parentQuestionPage = survey.value?.pages.find((page) => {
    return page.questions.find((question) => {
      return question.id === dependantQuestion.id
    })
  })
  if (parentQuestionPage === undefined) {
    return undefined
  }
  const parentQuestion = parentQuestionPage.questions.find((parentQuestion) => {
    if (parentQuestion.id === dependantQuestion.id) {
      return parentQuestion
    }
  })
  return parentQuestion
}

function getDependantValue(dependantQuestion, parentQuestion): boolean {
  let isVisibleArray: Array<boolean> = []
  if (typeof dependantQuestion.value === 'boolean' || typeof parentQuestion.answer.value === 'boolean') {
    isVisibleArray.push(dependantQuestion.value.toString() === parentQuestion.answer.value.toString())
  } else if (Array.isArray(parentQuestion.answer.value)) {
    isVisibleArray.push(parentQuestion.answer.value.some((v) => v === dependantQuestion.value))
  } else {
    isVisibleArray.push(dependantQuestion.value === parentQuestion.answer.value)
  }
  return isVisibleArray[0]
}

function isAskedPageEmpty(page) {
  let isPageEmpty: boolean = true
  survey.value?.pages[page - 1].questions.forEach((question) => {
    if (isQuestionVisible(question)) {
      isPageEmpty = false
    }
  })
  return isPageEmpty
}

// function showVariableGlobalIfNotEmpty(question: Question, isObect?: boolean) {
//   if ((question.isGlobal && !showGlobalVariables.value) || isObect) {
//     if (
//       question.type === "text" ||
//       question.type === "number" ||
//       question.type === "date"
//     ) {
//       return question.answer.value === "";
//     } else if (question.type === "choice") {
//       return question.answer.value === "";
//     } else if (question.type === "multi") {
//       return question.answer.value.length === 0;
//     } else if (question.type === "object") {
//       let error = false;
//       question.options.map((option) => {
//         if (showVariableGlobalIfNotEmpty(option, true)) {
//           error = true;
//         }
//       });
//       return error;
//     } else if (question.type === "unisign") {
//       const isError =
//         question.answer.value.email === "" ||
//         question.answer.value.firstName === "" ||
//         question.answer.value.lastName === "";
//       return isError;
//     } else if (question.type === "array") {
//       return question.answer.value.length === 0;
//     } else if (question.type === "multisign") {
//       if (question.answer.value.length === 0) {
//         return question.answer.value.length === 0;
//       }
//       if (question.answer.value.length === 1) {
//         return (
//           question.answer.value[0].email === "" ||
//           question.answer.value[0].firstName === "" ||
//           question.answer.value[0].lastName === ""
//         );
//       }
//     } else if (question.type === "multiarray") {
//       return question.answer.value.length === 0;
//     }
//   } else {
//     return true;
//   }
// }

function showVariableGlobalIfNotEmpty(question: Question) {
  // return true;
  if (showGlobalVariables.value) {
    return true
  }
  if (question.isGlobal && !showGlobalVariables.value) {
    return question.isGlobal && !question.isGlobalValue
  }
}

async function putSurvey() {
  let data: Survey = JSON.parse(JSON.stringify(survey.value))
  data.pages = data.pages.filter((p, index) => {
    return index + 1 === +route.query.page
  })
  await userApi.putSurvey(route.params.documentId, data)
}

async function goToSummary() {
  try {
    isRecapButtonLoading.value = true
    await putSurvey()
    isRecapButtonLoading.value = false
    router.push({
      path: `${route.path}/summary`,
    })
  } catch (e) {
    Notify.create({
      message: `Erreur lors de la sauvegarde des réponses de la page`,
      type: 'negative',
    })
    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  }
}

async function goToPage(pageAsked, isNext: boolean) {
  try {
    isDisable.value = true
    isNext ? (isNextLoading.value = true) : (isPreviousLoading.value = true)
    await putSurvey()
    if (isAskedPageEmpty(pageAsked)) {
      if (pageAsked === survey.value?.pages.length) {
        router.push({
          path: `${route.path}/summary`,
        })
      } else {
        isNext ? goToPage(pageAsked + 1, true) : goToPage(pageAsked - 1, false)
      }
    } else {
      router.push({
        path: route.path,
        query: { page: pageAsked },
      })

      window.scrollTo({ top: 0, behavior: 'smooth' })
      page.value = pageAsked
    }

    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  } catch (e) {
    Notify.create({
      message: `Erreur lors de la sauvegarde des réponses de la page`,
      type: 'negative',
    })
    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  }
}

function onBack() {
  if (route.params.clientId && route.params.projectId) {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: { taskId: route.params.taskId },
    })
  } else {
    router.push({
      name: 'dashboard/main',
    })
  }
}

console.log("survey.value", survey.value)

const showPreview = ref(false)
const splitterModel = ref(100)

watch(() => showPreview.value, (value) => {
  if (value) {
    splitterModel.value = 50
    nextTick(() => {
      document.getElementById('documentPreviewIframe').src = getIframeSrc()
    })
  } else {
    splitterModel.value = 100
  }
})

const previewFilename = ref('')
const documentGenerationRunning = ref(false)

async function generatePreview() {
  // showLoading()
  documentGenerationRunning.value = true
  console.log("iframeref", iFrameRef.value)
  // iFrameRef.value.src = ""
  await putSurvey()
  const result = await
    userApi
      .generateDocument(route.params.documentId, route.params.clientId, route.params.projectId, route.params.taskId)

  document.getElementById('documentPreviewIframe').src = ""

  // .then((result) => {

  console.log('result', result)
  console.log("iframeref", iFrameRef.value)
  document.getElementById('documentPreviewIframe').src = getIframeSrc()
  // try {
  //   iFrameRef.value.src = getIframeSrc()
  // } catch (e) {
  //   console.log('error', e)
  // }
  documentGenerationRunning.value = false
  // })
  //     .catch ((e) => {
  //   console.log('error', e)
  //   // $q.loading.hide()
  //   Notify.create({
  //     message: 'Une erreur est survenue lors de la génération du document',
  //     type: 'negative',
  //   })
  //   console.log("iframeref", iFrameRef.value)
  //   try {
  //     iFrameRef.value.src = getIframeSrc()
  //   } catch (e) {
  //     console.log('error', e)
  //   }

  //   documentGenerationRunning.value = false
  // })

}

function getIframeSrc() {
  console.log("getIframeSrc")
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  previewFilename.value = encodeURIComponent("Prévisualisation")
  return BASE_URL + `api/v2/tasks/convert_generated_file_to_pdf/${route.params.documentId}/${previewFilename.value}?type=preview&timestamp=${new Date().getTime()}`
}

</script>
<template>
  <div class="column">
    <q-btn outline icon="arrow_back" class="q-mt-md" style="width: fit-content" label="Retour" @click="onBack" />
    <div class="row justify-between q-mt-md items-center">
      <span class="document-title">{{ survey?.documentName }}</span>
      <div>
        <q-toggle v-model="showGlobalVariables" label="Voir les réponses déjà renseignées" left-label color="primary"
          keep-color checked-icon="visibility" unchecked-icon="visibility_off" size="lg" />
        <q-toggle v-model="showPreview" label="Voir la prévisualisation" left-label color="primary" keep-color
          checked-icon="visibility" unchecked-icon="visibility_off" size="lg" />
      </div>
    </div>
    <BaseProgressBar :pages="survey?.pages" class="q-mb-lg"></BaseProgressBar>

    <col>
    <q-splitter v-model="splitterModel" style="width: 100%">
      <template v-slot:before>


        <q-scroll-area class="questions-scroll-area">

          <span class="page-title">{{ survey?.pages[page - 1] ? survey?.pages[page - 1].name : `Page ${page}` }}</span>

          <div v-if="survey?.pages[page - 1]?.description"
            class="description q-mt-sm q-mb-md q-pa-sm row items-center no-wrap">
            <div>
              <q-icon name="info" size="md" class="info q-mr-sm"></q-icon>
            </div>
            <div>{{ survey.pages[page - 1]?.description }}</div>
          </div>

          <div style="height: 16px;"></div>

          <div v-for="question in survey?.pages[page - 1]?.questions" :key="question.id">
            <SurveyQuestion :question="question" :survey="survey"
              v-if="isQuestionVisible(question) && showVariableGlobalIfNotEmpty(question)"></SurveyQuestion>
          </div>
          <div class="column items-center q-pt-lg" v-if="!showGlobalVariables && isAllQuestionHidden">
            <q-toggle v-model="showGlobalVariables" left-label color="primary" keep-color checked-icon="visibility"
              unchecked-icon="visibility_off" size="80px" />
            <span class="empty-sentence">
              La page n'est pas réellement vide, vous pouvez afficher les questions ayant déjà une réponse.
            </span>
          </div>
        </q-scroll-area>


      </template>

      <template v-slot:after>
        <div v-if="showPreview" style="display: block;">
          <div style="display: flex; align-items: center; padding-bottom: 0px; margin-left: 16px; margin-bottom: 16px;">
            <q-btn :disabled="documentGenerationRunning" color="primary" label="Générer le document" style=""
              @click="generatePreview()" />
            <div v-if="documentGenerationRunning" style="margin-left: 24px;">
              Prévisualisation en cours de création...
            </div>
          </div>
          <!-- <iframe :src="getIframeSrc()" class="iframe" frameborder="0" :ref="iFrameRef"></iframe> -->
          <iframe id="documentPreviewIframe" src="" class="iframe" frameborder="0"></iframe>
        </div>
      </template>
    </q-splitter>
    </col>
    <BaseFooter>
      <template #content>
        <q-btn :disable="page === 1 || isDisable" flat color="white" label="Précédent" icon="navigate_before"
          size="22px" @click="goToPage(+route.query.page - 1, false)" :loading="isPreviousLoading" />
        <q-btn flat color="white" label="Voir récapitulatif" icon-right="done_all" size="22px"
          :loading="isRecapButtonLoading" :disable="isDisable" @click="goToSummary" />
        <q-btn v-if="page < survey?.pages.length" flat color="white" label="Suivant" icon-right="navigate_next"
          size="22px" :loading="isNextLoading" :disable="isDisable" @click="goToPage(+route.query.page + 1, true)" />
      </template>
    </BaseFooter>

  </div>
</template>
<style lang="scss" scoped>
.questions-scroll-area {
  height: calc(100vh - 310px);
}

.document-title {
  font-size: 24px;
  font-weight: 500;
}

.page-title {
  font-size: 24px;
  font-weight: 500;
}

.description {
  border: solid;
  background-color: #eeeeee;
  border-color: #cfd8dc;
  border-width: 0px 8px;
  border-radius: 4px;

  .info {
    color: rgba(64, 104, 200, 0.48);
  }
}

.empty-sentence {
  color: gray;
  font-size: 26px;
  font-weight: 400;
}

.iframe {
  // margin-left: 1vw;
  width: calc(100% - 0px);
  height: calc(100vh - 320px);
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23000000" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="16" text-anchor="middle">Veuillez patienter pendant la récupération de la prévisualisation...</text></svg>') 0px 0px no-repeat;
  // overflow:hidden;
  // display: block;
  // height: 1600px;
  // border: 1px solid red;
}

.scroll-area {
  width: calc(100% - 0px);
  height: calc(100% - 76px);
  // border: 1px solid blue;
}
</style>
