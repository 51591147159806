<script lang="ts" setup>
/**
 * This component is the dialog in Documents under Actions/Traitements automatiques
*/
import { ref, Ref, inject, computed, provide } from 'vue'
import { ProjectKey } from '@/models/symbols'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'


interface Props {
  project: any,
  ticked: Array<string>,
  selectAllTreeNodes: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const title = "Traitements automatiques"

const doClassify = ref(false)
const doExtract = ref(false)
const treeNodeTemplateName = ref('Arborescence par défaut')

async function launchTreatments() {
  try {
    isLoading.value = true;
    await userApi.batchTreatment(
      props.project?.value.id,
      props.ticked.value,
      doClassify.value,
      doExtract.value
    );
    console.log('Launched automated treatment')
    emit("ok");
    isLoading.value = false;
    Notify.create({
      message: "Les traitements ont bien été lancés.",
      type: "primary",
    });
  } catch (e) {
    dialogRef.value?.hide();
    isLoading.value = false;
    console.error(e)
    Notify.create({
      message: "Une erreur est survenue",
      type: "negative",
    });
  }
  emit('ok')
}

</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      :title="title"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >

      <template #body>
          <div v-if="true">
            <BaseCheckbox v-model="doExtract" label="Résumer les documents et extraire les champs"/>
            <!-- <BaseCheckbox v-model="doClassify" label="Classer les documents automatiquement" :disable="!props.selectAllTreeNodes"
            on-hover="Vous devez avoir sélectionné l'ensemble des documents pour pouvoir les classer automatiquement."/> -->
            <BaseCheckbox v-model="doClassify" label="Ranger automatiquement les documents dans une arborescence type" :disable="!props.selectAllTreeNodes">
              <q-tooltip v-if="!props.selectAllTreeNodes">Vous devez avoir sélectionné l'ensemble des documents pour pouvoir les classer automatiquement.</q-tooltip>
            </BaseCheckbox>
            <div v-if="doClassify" class="q-pa-sm q-pl-lg">
              <q-icon name="warning" size="md" color="alert" />
              <span style="display: inline-block">
                Attention, cette opération va ranger l'ensemble des pièces du dossier.
                <!-- Sélectionnez l'arborescence dans laquelle trier les documents -->
              </span>
              <q-select :options="['Arborescence par défaut']" v-model="treeNodeTemplateName" />
            </div>
          </div>
      </template>
      <template #actions>
        <q-btn flat label="Lancer le traitement" :disable="!doClassify && !doExtract"  @click="launchTreatments" />
      </template>

    </BaseDialog>
  </q-dialog>
</template>
