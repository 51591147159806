<script setup lang="ts">
import { ref, Ref, inject } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  // users: any[]
  project: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const users: Ref<Array<any>> = ref([])

users.value = await Promise.all(
  accountableOptions?.value.map(async (user) => {
    const notificationsEnabled = await userApi.getProjectNotification(project?.value.id, user.id)

    return {
      id: user.id,
      fullName: user.fullName,
      notificationsEnabled,
    }
  }),
)

// Set authenticated user on top of the list
users.value = users.value.sort((a, b) => {
  if (a.id === authenticatedUser.id) {
    return -1
  }
  if (b.id === authenticatedUser.id) {
    return 1
  }
  return 0
})


async function toggleNotificationForUser(user) {
  try {
    if (user.notificationsEnabled) {
      await userApi.deleteProjectNotification(props.project.id, user.id)
    } else {
      await userApi.postProjectNotification(props.project.id, user.id)
    }

    user.notificationsEnabled = !user.notificationsEnabled
    Notify.create({
      message: user.notificationsEnabled ? 'Notifications activées' : 'Notifications désactivées',
      type: 'primary',
    })
  } catch (e) {
    Notify.create({
      message: user.notificationsEnabled
        ? 'Erreur lors de la désactivation des notifications'
        : "Erreur lors de l'activation des notifications",
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Centre de notifications"
      caption="Gérez les notifications par emails des utilisateurs pour ce dossier." :showCancelButton="false"
      @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <slot>
          <q-list bordered separator>
            <q-item v-for="user in users" :key="user.fullName" tag="label">
              <q-item-section>
                <q-item-label>{{ user.fullName }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <div class="row justify-between items-center">
                  <q-badge outline color="primary" v-if="user.notificationsEnabled"> activées </q-badge>
                  <q-btn round flat color="primary" icon="notifications" :disable="isLoading"
                    @click="toggleNotificationForUser(user)" v-if="user.notificationsEnabled">
                    <q-tooltip>Notifications activées</q-tooltip>
                  </q-btn>
                </div>
                <q-btn round flat color="black" icon="o_notifications_off" :disable="isLoading"
                  @click="toggleNotificationForUser(user)" v-if="!user.notificationsEnabled">
                  <q-tooltip>Notifications désactivées</q-tooltip>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </slot>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
