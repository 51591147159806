const mainMenu = {
  menu: {
    sections: [
      {
        name: 'home',
        label: 'Accueil',
        link: 'dashboard/main',
        icon: 'o_home',
        iconSelected: 'home',
        roles: ['technical', 'admin', 'finance'],
        items: [
          // {
          //   label: "Tâches",
          //   link: "dashboard/tasks",
          //   roles: ["admin"],
          // },
        ],
      },

      {
        name: 'contents',
        label: 'Contenus',
        link: 'contents/main',
        icon: 'o_dynamic_feed',
        iconSelected: 'dynamic_feed',
        roles: ['admin', 'finance'],
        items: [
          {
            label: 'Projets juridiques',
            link: 'contents/projects',
            roles: ['admin', 'finance'],
            // permission: 'manage_platformprojectcatalog',
          },
          {
            label: 'Modèles unitaires',
            link: 'contents/document_templates',
            roles: ['admin', 'finance'],
            // permission: 'manage_environmentdocumentcatalog',
          },
        ],
      },

      {
        name: 'database',
        label: 'Dossiers permanents',
        link: 'database/main',
        icon: 'o_folder_copy',
        iconSelected: 'folder_copy',
        roles: ['admin', 'finance'],
        // permission: 'manage_database_entities',
        items: [],
      },

      {
        name: 'subscriptions',
        label: 'Abonnements',
        link: 'subscriptions/main',
        icon: 'o_subscriptions',
        iconSelected: 'subscriptions',
        roles: ['admin', 'finance'],
        // permission: 'manage_subscription_modules',
        items: [],
      },

      {
        name: 'environments',
        label: 'Marques blanches',
        link: 'environments/main',
        icon: 'o_workspaces',
        iconSelected: 'workspaces',
        roles: ['admin', 'finance'],
        // permission: 'manage_environments',
        items: [],
      },

      // {
      //   name: "infrastructure",
      //   label: "Infrastructure",
      //   link: "infrastructure/main",
      //   icon: "storage",
      //   roles: ["technical", "admin"],
      //   items: [
      //     {
      //       label: "Monitoring",
      //       link: "infrastructure/monitoring",
      //       roles: ["technical", "admin"],
      //     },
      //     {
      //       label: "Logs",
      //       link: "infrastructure/logs",
      //       roles: ["technical", "admin"],
      //     },
      //   ],
      // },
      // {
      //   name: "support",
      //   label: "Support",
      //   link: "support/main",
      //   icon: "support",
      //   roles: ["technical", "admin"],
      //   items: [
      //     {
      //       label: "Intermédiaires",
      //       link: "support/environment",
      //       roles: ["technical", "admin"],
      //     },
      //     {
      //       label: "Client final",
      //       link: "support/client",
      //       roles: ["technical", "admin"],
      //     },
      //   ],
      // },
      // // {
      // //   name: "settings",
      // //   label: "Paramètres",
      // //   link: "settings/main",
      // //   icon: "manage_accounts",
      // //   roles: ["technical", "admin", "finance"],
      // //   items: [
      // //     {
      // //       label: "Profil",
      // //       link: "settings/profile",
      // //       roles: ["technical", "admin", "finance"],
      // //     },
      // //     {
      // //       label: "Thème",
      // //       link: "settings/theme",
      // //       roles: ["technical", "admin", "finance"],
      // //     },
      // //   ],
      // // },

      {
        name: 'users',
        label: 'Utilisateurs',
        link: 'administration/users',
        icon: 'o_people',
        iconSelected: 'people',
        roles: ['admin', 'finance'],
        items: [],
      },

      // {
      //   name: "administration",
      //   label: "Administration",
      //   link: "administration/main",
      //   icon: "admin_panel_settings",
      //   roles: ["admin", "finance"],
      //   items: [
      //     // {
      //     //   label: "Rôles",
      //     //   link: "administration/roles",
      //     //   roles: ["admin", "finance"],
      //     // },
      //     {
      //       label: "Utilisateurs",
      //       link: "administration/users",
      //       roles: ["admin", "finance"],
      //     },
      //     // {
      //     //   label: "Plateformes intermédiaires",
      //     //   link: "administration/environments",
      //     //   roles: ["admin", "finance"],
      //     // },
      //     // {
      //     //   label: "Modèles de projets",
      //     //   link: "administration/project_templates_editor",
      //     //   roles: ["admin", "finance"],
      //     // },
      //     // {
      //     //   label: "Modèles de documents",
      //     //   link: "administration/document_templates_editor",
      //     //   roles: ["admin", "finance"],
      //     // },
      //   ],
      // },

      {
        name: 'aitreatment',
        label: 'Traitement IA',
        link: 'aitreatment/main',
        icon: 'admin_panel_settings',
        iconSelected: 'o_admin_panel_settings',
        roles: ['admin', 'finance'],
        items: [],
      },

      {
        name: 'v2',
        label: 'PoC v2',
        link: 'v2/main',
        icon: 'admin_panel_settings',
        roles: ['admin', 'finance'],
        items: [
          {
            label: 'Editeur de projets',
            link: 'v2/project_editor',
            roles: ['admin', 'finance'],
          },
          {
            label: 'Editeur de document',
            link: 'v2/document_editor',
            roles: ['admin', 'finance'],
          },
          {
            label: 'Générateur de document',
            link: 'v2/document_generator',
            roles: ['admin', 'finance'],
          },
          {
            label: 'Liste des documents',
            link: 'v2/documents_list',
            roles: ['admin', 'finance'],
          },
        ],
      },

      {
        name: 'v2',
        label: 'Tests',
        link: 'v2/main',
        icon: 'admin_panel_settings',
        roles: ['admin', 'finance'],
        items: [
          {
            label: 'Drag and drop',
            link: 'v2/drag_and_drop',
            roles: ['admin', 'finance'],
          },
          {
            label: 'WebSocket',
            link: 'v2/web_socket',
            roles: ['admin', 'finance'],
          },
          {
            label: 'PDF',
            link: 'v2/pdf',
            roles: ['admin', 'finance'],
          },
          {
            label: 'concat PDF',
            link: 'v2/concat_pdf',
            roles: ['admin', 'finance'],
          },
          {
            label: 'test api',
            link: 'v2/test_api',
            roles: ['admin', 'finance'],
          },
        ],
      },
    ],
  },
}

export { mainMenu }
